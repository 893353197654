import { UnregisteredUser } from '@index/interfaces/unregistered-user';

import { GthModel } from './model';

export class GthUnregisteredUserModel extends GthModel<UnregisteredUser> {
  constructor(id: string, model: UnregisteredUser) {
    super(id, model);
  }

  get user() {
    return this.model;
  }

  get id(): string {
    return this._id;
  }

  get uid(): string {
    return this.model.uid;
  }

  get email() {
    return this.user.email;
  }

  get fullName() {
    return this.user.fullName;
  }

  get phoneNumber() {
    return this.user.phoneNumber;
  }

  get createdAt() {
    return this.user.createdAt;
  }

  get updatedAt() {
    return this.user.updatedAt;
  }

  get displayNameFallback() {
    if (this.fullName) {
      return this.fullName;
    }
    return this.email;
  }

  get teamInvites() {
    return this.user.teamInvites;
  }
}
