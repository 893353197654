import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TeamRosterItem } from '@index/interfaces';
import { UnregisteredUser } from '@index/interfaces/unregistered-user';
import { EventJoinerModel } from '@index/models/event-joiner';
import { DBUtil } from '@index/utils/db-utils';
import { GthUnregisteredUserModel } from '@sentinels/models/unregistered-user';
import { FirestoreService } from '@sentinels/services/core/firebase.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import firebase from 'firebase/compat/app';
import { catchError, EMPTY, first, forkJoin, from, map, mergeMap, Observable, of, switchMap, toArray } from 'rxjs';

export interface UnregisteredAccountDetails {
  email: string;
  fullName: string;
  phoneNumber?: string;
  teamInvite?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UnregisteredUserService extends FirestoreService<UnregisteredUser> {
  protected basePath: string = 'unregistered-users';

  private snackbar = inject(MatSnackBar);
  private user = inject(UserService);

  createGuestAccount$(details: UnregisteredAccountDetails) {
    if (!details.email || !details.fullName) {
      throw new Error('Email and full name are required.');
    }

    const userByEmail = this.user.getUserByEmail$(details.email).pipe(first());
    const unregisteredUserByEmail = this.getUnregisteredUserByEmail$(details.email).pipe(
      first(),
    );

    return forkJoin([userByEmail, unregisteredUserByEmail]).pipe(
      switchMap(([user, unregisteredUser]) => {
        if (user) {
          // eslint-disable-next-line max-len
          throw new Error('User already exists with this email address. Please login instead.');
        }
        if (unregisteredUser) {
          if (details.teamInvite) {
            this.doc(unregisteredUser.uid).update({
              teamInvites: [...unregisteredUser.teamInvites, details.teamInvite],
              updatedAt: firebase.firestore.Timestamp.now(),
            });
          }
          return of(unregisteredUser.uid);
        }
        const uid = this.firestore.createId();
        const userObj: UnregisteredUser = {
          uid,
          email: details.email,
          fullName: details.fullName,
          teamInvites: details.teamInvite ? [details.teamInvite] : [],
          createdAt: firebase.firestore.Timestamp.now(),
          updatedAt: firebase.firestore.Timestamp.now(),
        };
        return from(this.doc(uid).set(userObj)).pipe(map(() => uid));
      }),
      catchError((error) => {
        this.snackbar.open(error.message, 'OK');
        console.error(error);
        return EMPTY;
      }),
    );
  }

  deleteGuestAccount$(uid: string) {
    return from(this.doc(uid).delete()).pipe(first());
  }

  getUnregisteredUserByEmail$(email: string): Observable<GthUnregisteredUserModel | null> {
    return this.collection$((ref) => ref.where('email', '==', email)).pipe(
      map((users) => {
        return users.length > 0 ? new GthUnregisteredUserModel(users[0].uid, users[0]) : null;
      }),
    );
  }

  getUnregisteredUser$(uid: string): Observable<GthUnregisteredUserModel | null> {
    return this.doc$<UnregisteredUser>(uid).pipe(
      map((user) => {
        return user?.uid ? new GthUnregisteredUserModel(user.uid, user) : null;
      }),
    );
  }

  // TODO(srevier): Send invite notifications instead of making member immediately
  updateTeamRosters$(teams: string[], uid: string, newUid: string) {
    return from(teams).pipe(
      mergeMap((teamId) =>
        this.firestore.collection(DBUtil.TeamRoster,
          (ref) => ref.where('teamId', '==', teamId),
        ).snapshotChanges().pipe(first()),
      ),
      map((actions) => actions.map((a) => {
        const data = a.payload.doc.data() as TeamRosterItem;
        if (!data) return null;
        if (data.userId === uid) {
          data.userId = newUid;
          data.unregisteredUser = false;
          data.role = 'Member';
        }
        return this.firestore.doc(`${DBUtil.TeamRoster}/${a.payload.doc.id}`).update(data);
      })),
      toArray(),
    );
  }

  updateUnregisteredUserJoiners$(uid: string, newUid: string): Observable<EventJoinerModel[]> {
    return this.collectionGroup$(DBUtil.EventJoiner,
      (query) => query.where('player', '==', uid),
    ).pipe(
      switchMap((joiners) => {
        if (joiners.length === 0) {
          return of([]);
        }
        const dataObservables = joiners.map((joiner) => {
          const ref = joiner.payload.doc.ref;
          ref.update({
            isUnregisteredUser: false,
            player: newUid,
          });
          return from(ref.get()).pipe(
            map((doc) => doc.data() as EventJoinerModel),
          );
        });
        return forkJoin(dataObservables);
      }),
    );
  }

  signUpUnregisteredUser$(uid: string, newUid: string) {
    return this.getUnregisteredUser$(uid).pipe(
      first(),
      switchMap((user) => {
        if (!user) {
          throw new Error('Guest account not found.');
        }
        return forkJoin([
          this.updateTeamRosters$(user.teamInvites, uid, newUid),
          this.updateUnregisteredUserJoiners$(uid, newUid),
        ]);
      }),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      }),
    );
  }
}
